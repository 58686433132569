<template>
  <!--Согласие-->
  <div id="consent" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">Оферта о продаже услуг</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4 ml-auto">
                ФИО: {{ GET_USER.name }}
                <br>
                Тел: {{ GET_USER.phone }}
                <br>
                Email: {{ GET_USER.email }}
              </div>
            </div>
            <div class="row pt-5 text-justify">
              <div class="col-md-12">
                <h4 class="text-center">Договор публичной оферты о продаже услуг № {{ GET_USER.contract }}</h4>
                <h5 class="text-center">1.Общие положения:</h5>
                <p>
                  1.1. Пигальцев Руслан Валерьевич, далее «Продавец», публикует Публичную оферту о реализации Услуг, размещенных на официальном интернет-сайте Продавца
                  <a target="_blank" href="">https://hosting.support-help.org/</a>
                </p>
                <p>
                  1.2. В соответствии со статьей 437 Гражданского Кодекса Российской Федерации (ГК РФ) данный документ является публичной офертой, и в случае принятия изложенных
                  ниже условий физическое лицо, производящее акцепт этой оферты, осуществляет оплату Услуги Продавца в соответствии с условиями настоящего Договора. В соответствии
                  с пунктом 3 статьи 438 ГК РФ, оплата Услуги Покупателем является акцептом оферты, что считается равносильным заключению Договора на условиях, изложенных в оферте.
                </p>
                <p>
                  1.3. На основании вышеизложенного внимательно ознакомьтесь с текстом публичной оферты, и, если вы не согласны с каким-либо пунктом оферты, Вам предлагается
                  отказаться от использования Услуг, предоставляемых Продавцом.
                </p>
                <p>
                  1.4. В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют следующие значения:
                </p>
                <ul>
                  <li>«Оферта» – публичное предложение Продавца, адресованное любому физическому лицу (гражданину), заключить с ним договор на существующих условиях, содержащихся в
                    Договоре, включая все его приложения.
                  </li>
                  <li>«Покупатель» – физическое лицо, заключившее с Продавцом Договор на условиях, содержащихся в Договоре.</li>
                  <li>«Акцепт» – полное и безоговорочное принятие Покупателем условий Договора.</li>
                  <li>«Услуга» – перечень наименований ассортимента, представленный на официальном интернет-сайте.</li>
                  <li>«Заказ» – отдельные позиции из ассортиментного перечня Услуг, указанные Покупателем при оформлении заявки на интернет-сайте или через Оператора.</li>
                </ul>
                <h5 class="text-center">2. Предмет договора</h5>
                <p>
                  2.1. Продавец реализует Услугу в соответствии с действующим прейскурантом, опубликованным на интернет-сайте Продавца
                  <a href="">hosting.support-help.org</a>, а Покупатель производит оплату и принимает Услугу в соответствии с условиями настоящего Договора.
                </p>
                <p>
                  2.2. Настоящий Договор и приложения к нему являются официальными документами Продавца и неотъемлемой частью оферты.
                </p>
                <h5 class="text-center">3. Оформление Заказа</h5>
                <p>
                  3.1. Заказ Услуги осуществляется Покупателем через Интернет-сайт
                  <a target="_blank" href="">https://hosting.support-help.org/</a>.
                </p>
                <p>
                  3.2. При регистрации на интернет-сайте Продавца Покупатель обязуется предоставить следующую регистрационную информацию о себе:
                </p>
                <ul>
                  <li>фио;</li>
                  <li>адрес электронной почты;</li>
                  <li>номер мобильного телефона;</li>
                </ul>
                <p>
                  3.3. При оформлении Заказа через Оператора Покупатель обязуется предоставить информацию, указанную в п. 3.2. настоящего Договора. Принятие Покупателем условий
                  настоящего Договора осуществляется посредством внесения Покупателем соответствующих данных в регистрационную форму на Интернет-сайте или при оформлении Заказа
                  через Оператора. Покупатель имеет право редактировать регистрационную информацию о себе. Оператор не изменяет и не редактирует регистрационную информацию о
                  Покупателе без согласия последнего. Продавец обязуется не сообщать данные Покупателя, указанные при регистрации на сайте
                  <a target="_blank" href="">https://hosting.support-help.org/</a>. и при оформлении Заказа, лицам, не имеющим отношения к исполнению Заказа.
                  Утвердив Заказ выбранной Услуги, Покупатель предоставляет Оператору необходимую информацию в соответствии с порядком, указанном в п. 3.2. настоящего Договора.
                </p>
                <p>
                  3.4. Продавец и Оператор не несут ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.
                </p>
                <p>
                  3.5. Покупатель несёт ответственность за достоверность предоставленной информации при оформлении Заказа.
                </p>
                <p>
                  3.6. Оплата Покупателем самостоятельно оформленного на интернет-сайте Заказа означает согласие Покупателя с условиями настоящего Договора. День оплаты Заказа
                  является датой заключения Договора между Продавцом и Покупателем.
                </p>
                <p>
                  3.7. Все информационные материалы, представленные на сайте
                  <a target="_blank" href="">https://hosting.support-help.org/</a>, носят справочный характер и не могут в полной мере передавать достоверную информацию об
                  определенных свойствах и характеристиках Услуги. В случае возникновения у Покупателя вопросов, касающихся Услуги, перед оформлением Заказа ему необходимо
                  обратиться за консультацией к Оператору.
                </p>
                <h5 class="text-center">4. Сроки исполнения Заказа</h5>
                <p>
                  4.1. Срок исполнения Заказа зависит от типа Услуги и времени, необходимого на обработку Заказа. Срок исполнения Заказа в исключительных случаях может быть
                  оговорен с Покупателем индивидуально в зависимости от типа предоставляемых Услуг. В случае невозможности оказания Услуги Продавцом, в том числе по причинам, не
                  зависящим от последнего, Продавец вправе аннулировать указанную Услугу из Заказа Покупателя. Продавец обязуется уведомить Покупателя об изменении его Заказа через
                  Оператора.
                </p>
                <p>
                  4.2. Заказ считается выполненным в момент принятия услуги Покупателем.
                </p>
                <p>
                  4.3. В случае предоставления Покупателем недостоверной информации его контактных данных Продавец за ненадлежащее исполнение Заказа ответственности не несет.
                </p>
                <p>
                  4.4. В случае ненадлежащего исполнения Заказа по вине Продавца повторная реализация Заказа осуществляется бесплатно.
                </p>
                <h5 class="text-center">5. Оплата Заказа</h5>
                <p>
                  5.1. Оплата исполненного Заказа осуществляется путем передачи Покупателем денежных средств сотруднику Продавца. Подтверждением оплаты исполненного Заказа является
                  чек.
                </p>
                <p>
                  5.2. Цены на Услуги, указанные на интернет-сайте
                  <a target="_blank" href="">https://hosting.support-help.org/</a>, могут быть изменены Продавцом в одностороннем порядке без уведомления Покупателя.
                  В случае изменения цены на заказанные Услуги, Оператор обязуется в кратчайшие сроки проинформировать Покупателя о таком изменении. Покупатель вправе подтвердить
                  либо аннулировать Заказ. В случае отсутствия связи с Покупателем Заказ считается аннулированным в течение 14 календарных дней с момента оформления.
                </p>
                <p>
                  5.3.Денежные средства принимаются двумя способами: наличными, безналичным платежом
                </p>
                <h5 class="text-center">6. Возврат Заказа</h5>
                <p>
                  6.1. В соответствии с п. 4. ст. 26.1. Закона РФ № 2300-I «О Защите прав потребителей» Покупатель вправе отказаться от заказанной Услуги в любое время до момента
                  исполнения Заказа.
                </p>
                <p>
                  6.2. Покупатель не вправе отказаться от оплаченного Заказа (или его части) надлежащего качества, имеющего индивидуально определённые свойства.
                </p>
                <p>
                  6.3. В случае предоставления Услуги ненадлежащего качества, Покупатель обязуется обратиться к Продавцу в максимально короткие сроки.
                </p>
                <p>
                  6.4. В соответствии со ст. 22 Закона РФ № 2300-I «О Защите прав потребителей» уплаченная Покупателем сумма за Услугу ненадлежащего качества подлежит возврату
                  Покупателю в течение 10 календарных дней с момента предъявления соответствующего требования. Возврат денежных средств осуществляется наличными в офисе Продавца. В
                  случае, если Услуга был оплачена через систему электронных платежей, то возврат денежных средств осуществляется на электронный счет Покупателя в течение 5-ти
                  рабочих дней.
                </p>
                <p>
                  6.5. Для возврата денежных средств за Услугу надлежащего качества (оказанную в соответствии с описанием, размещенным на интернет-сайте
                  <a target="_blank" href="">https://hosting.support-help.org/</a>), должны быть выполнены следующие условия:
                </p>
                <ul>
                  <li>Наличие подтверждения оплаты</li>
                </ul>
                <p>В остальных случаях возврат денежных средств за услугу надлежащего качества невозможен.</p>
                <p>
                  6.6. Для возврата денежных средств за услугу не надлежащего качества (оказанную не в соответствии с описанием, размещенным на интернет-сайте
                  <a target="_blank" href="">https://hosting.support-help.org/</a>),
                  составляются накладная или акт о возврате денежных средств, в котором указываются:
                </p>
                <ul>
                  <li>полное фирменное наименование Продавца;</li>
                  <li>фамилия, имя, отчество и паспортные данные Покупателя;</li>
                  <li>наименование Услуги;</li>
                  <li>причина возврата денежных средств за Услугу;</li>
                  <li>дата оказания Услуги;</li>
                  <li>сумма, подлежащая возврату;</li>
                  <li>подписи Продавца и Покупателя.</li>
                </ul>
                <p>
                  6.7 Для возврата денежных средств в случае отсутствия факта начала оказания услуги должны быть выполнены следующие условия:
                </p>
                <ul>
                  <li>Нарушение условий договора со стороны Продаца</li>
                </ul>
                <p>В остальных случаях возврат денежных средств за не оказанную Услугу невозможен.</p>
                <p>
                  6.8. Возврат стоимости Услуги производится не позднее чем через 10 (десять) дней с даты предоставления Покупателем соответствующего требования.
                </p>
                <p>
                  6.9. Для возврата денежных средств на банковскую карту Заказчику необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию
                  Компанией на электронный адрес Заказчика, и оправить его вместе с приложением копии паспорта по адресу:
                  <br>
                  Чувашская Республика - Чувашия, Канашский район, село Шихазаны, ул. 40 лет Победы, д. 3, кв. 2.
                  <br>
                  Возврат денежных средств будет осуществлен на банковский счет Заказчика, указанный в заявлении, в течение 10 (Десяти) рабочих дней со дня получения «Заявление о
                  возврате денежных средств» Компанией.
                </p>
                <p>
                  6.10. Для возврата денежных средств, зачисленных на расчетный счет Компании ошибочно посредством платежных систем, Заказчик должен обратиться с письменным
                  заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное зачисление. Данное заявление необходимо направить по адресу:
                  <br>
                  Чувашская Республика - Чувашия, Канашский район, село Шихазаны, ул. 40 лет Победы, д. 3, кв. 2.
                  <br>
                  После получения письменного заявления с приложением копии паспорта и чеков/квитанций Компания производит возврат в срок до 10 (десяти) рабочих дней со дня
                  получения 3аявления на расчетный счет Заказчика, указанный в заявлении. В этом случае, сумма возврата будет равняться стоимости Заказа.
                </p>
                <p>
                  6.11. Срок рассмотрения Заявления и возврата денежных средств Заказчику начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих
                  днях без учета праздников/выходных дней. Если заявление поступило Компании после 18.00 рабочего дня или в праздничный/выходной день, моментом получения Компанией
                  Заявления считается следующий рабочий день.
                </p>
                <p>
                  6.12. Требование о возврате Покупатель должен предоставить Продавцу либо в письменном виде по адресу Чувашская Республика - Чувашия, Канашский район, село
                  Шихазаны, ул. 40 лет Победы, д. 3, кв. 2, либо в электронном на адрес электронной почты
                  <a href="mailto:info@support-help.org">info@support-help.org</a>
                  . Требование может быть составлено в свободной форме с обязательным указанием контактных данных Покупателя, совпадающих с указанными при заказе Услуги,
                  наименования возвращаемого Услуги и причин возврата. Покупатель вправе задать все возникшие вопросы Продавцу по телефону 8(495) 506-95-54.
                </p>
                <h5 class="text-center">7. Авторские права</h5>
                <p>7.1. Вся текстовая информация и графические изображения, размещенные на интернет-сайте
                  <a target="_blank" href="https://hosting.support-help.org/">https://hosting.support-help.org/</a>, являются собственностью Продавца и/или его поставщиков.
                </p>
                <h5 class="text-center">8. Права, обязанности и ответственность</h5>
                <p>
                  8.1. Продавец не несет ответственности за ненадлежащее использование Услуг Покупателем, заказанных на интернет-сайте или через Оператора.
                </p>
                <p>
                  8.2. Продавец вправе передавать свои права и обязанности по исполнению Заказов третьим лицам.
                </p>
                <p>
                  8.3. Продавец имеет право на осуществление записи телефонных переговоров с Покупателем. В соответствии с п. 4 ст. 16 Федерального закона «Об информации,
                  информационных технологиях и о защите информации» Продавец обязуется: предотвращать попытки несанкционированного доступа к информации и/или передачу ее лицам, не
                  имеющим непосредственного отношения к исполнению Заказов; своевременно обнаруживать и пресекать такие факты. Телефонные разговоры записываются в целях
                  осуществления контроля деятельности Оператора и контроля качества исполнения Заказов.
                </p>
                <p>
                  8.4. Все претензии по ненадлежащему исполнению заказа Покупатель вправе направить на адрес электронной почты, указанный на интернет-сайте
                  <a target="_blank" href="https://hosting.support-help.org/">https://hosting.support-help.org/</a>
                  . Вся поступившая информация обрабатывается в кратчайшие сроки.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 offset-md-9">
                Пигальцев Руслан Валерьевич
                <br>
                <img class="signature" src="/img/signature.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Consent",
  computed: {
    ...mapGetters([
      'GET_USER'
    ])
  }
}
</script>
<style scoped>
.signature {
  width: 40%;
}
</style>
