<template>
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <router-link class="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
      <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div>
      <div class="sidebar-brand-text mx-3">CRM<sup>broker</sup></div>
    </router-link>
    <!-- Divider -->
    <hr class="sidebar-divider my-0">
    <router-link :to="'/home'" custom v-slot="{isActive, href, navigate}">
      <li class="nav-item" v-bind:class="{ active: isActive }">
        <a class="nav-link" :href="href" @click="navigate">
          <i class="fas fa-fw fa-tachometer-alt"></i> <span>Консоль управления</span></a>
      </li>
    </router-link>
    <!-- Divider -->
    <hr class="sidebar-divider">
    <!-- Heading -->
    <div class="sidebar-heading">
      Клиенты
    </div>
    <router-link to="/home/new-clients" custom v-slot="{isActive, href, navigate}">
      <li class="nav-item" v-bind:class="{ active: isActive }">
        <a class="nav-link" :href="href" @click="navigate">
          <i class="fas fa-fw fa-tachometer-alt"></i> <span>Новые клиенты</span></a>
      </li>
    </router-link>

    <!-- Divider -->
    <hr class="sidebar-divider">


    <div v-if="comment">
      <!-- Divider -->
      <hr class="sidebar-divider">
      <!-- Heading -->
      <div class="sidebar-heading">
        Interface
      </div>
      <!-- Nav Item - Pages Collapse Menu -->
      <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <i class="fas fa-fw fa-cog"></i> <span>Components</span>
        </a>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Custom Components:</h6>
            <a class="collapse-item" href="buttons.html">Buttons</a>
            <a class="collapse-item" href="cards.html">Cards</a>
          </div>
        </div>
      </li>
      <!-- Nav Item - Utilities Collapse Menu -->
      <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="false" aria-controls="collapseUtilities">
          <i class="fas fa-fw fa-wrench"></i> <span>Utilities</span>
        </a>
        <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Custom Utilities:</h6>
            <a class="collapse-item" href="utilities-color.html">Colors</a>
            <a class="collapse-item" href="utilities-border.html">Borders</a>
            <a class="collapse-item" href="utilities-animation.html">Animations</a>
            <a class="collapse-item" href="utilities-other.html">Other</a>
          </div>
        </div>
      </li>
      <!-- Divider -->
      <hr class="sidebar-divider">
      <!-- Heading -->
      <div class="sidebar-heading">
        Addons
      </div>
      <!-- Nav Item - Pages Collapse Menu -->
      <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
          <i class="fas fa-fw fa-folder"></i> <span>Pages</span>
        </a>
        <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Login Screens:</h6>
            <a class="collapse-item" href="login.html">Login</a>
            <a class="collapse-item" href="register.html">Register</a>
            <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
            <div class="collapse-divider"></div>
            <h6 class="collapse-header">Other Pages:</h6>
            <a class="collapse-item" href="404.html">404 Page</a>
            <a class="collapse-item" href="blank.html">Blank Page</a>
          </div>
        </div>
      </li>
      <!-- Nav Item - Charts -->
      <li class="nav-item">
        <a class="nav-link" href="charts.html">
          <i class="fas fa-fw fa-chart-area"></i> <span>Charts</span></a>
      </li>
      <!-- Nav Item - Tables -->
      <li class="nav-item">
        <a class="nav-link" href="tables.html">
          <i class="fas fa-fw fa-table"></i> <span>Tables</span></a>
      </li>
    </div><!-- comment -->

    <!-- Разделитель -->
    <hr class="sidebar-divider d-none d-md-block">
    <!-- Переключатель (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button v-on:click="this.$store.dispatch('TOGGLE_SIDENAV')" class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
    <!-- Sidebar сообщение () -->
    <div class="sidebar-card d-none d-lg-flex">
      <p class="text-center mb-2"><strong>Вы вошли как </strong>{{ this.$store.getters.GET_USER.name }}</p>
    </div>
  </ul>
</template>
<script>
export default {
  name: "Sidebar",
  mounted() {
  },
  created() {
    window.addEventListener('resize', () => {
      this.window.width = document.documentElement.clientWidth;
      if (document.documentElement.clientWidth < 768) {

        let link = document.querySelector('a[aria-expanded="true"]');
        if (link) {
          let collapse = link.nextSibling;
          link.setAttribute('aria-expanded', false);
          link.classList.add("collapsed");
          collapse.classList.remove('show');
        }

        if (document.documentElement.clientWidth < 480 && !this.$store.getters.GET_SIDENAV_TOGGLED) {
          this.$store.dispatch('TOGGLE_SIDENAV');
        }
      }

    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
    });
  },
  data() {
    return {
      comment: false,
      window: {
        width: 0
      }
    }
  },
}
</script>
<style scoped></style>
