<template>
  <Alert />
  <!-- Page Wrapper -->
  <div id="wrapper">
    <Loader v-if="this.$store.getters.GET_LOADER_VISIBLE" />
    <!-- Sidebar -->
    <Sidebar />
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- TopBar -->
        <TopBar />
        <!-- End of TopBar -->
        <router-view></router-view>
      </div>
      <!-- End of Main Content -->
      <!-- Footer -->
      <Footer />
      <!-- End of Footer -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <ScrollToTop />
  <LogoutModal />
  <Consent />
</template>
<script>
import Sidebar from "../components/static/Sidebar";
import TopBar from "../components/static/TopBar";
import Footer from "../components/static/Footer";
import ScrollToTop from "../components/static/ScrollToTop";
import LogoutModal from "../components/modals/LogoutModal";
import Consent from "../components/static/Consent";
import Loader from "../components/static/Loader";
import Alert from "../components/static/Alert";
export default {
  name: "HomeLayout",
  components: {Loader, Consent, LogoutModal, ScrollToTop, Footer, TopBar, Sidebar, Alert},
}
</script>
<style scoped></style>
