<template>
  <!--<loader />-->
  <component :is="layout"></component>
</template>
<script>
import AuthLayout from "./layouts/AuthLayout";
import HomeLayout from "./layouts/HomeLayout";
import SiteLayout from "./layouts/SiteLayout";

export default {
  name: "App",
  data(){},
  components: {AuthLayout, HomeLayout, SiteLayout},
  computed: {
    layout() {
      return (this.$route.meta.layout || 'SiteLayout');
    }
  }
}
</script>
<style lang="scss"></style>
