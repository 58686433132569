<template>
    <v-vanta effect="clouds" :options="options"></v-vanta>
    <Loader v-if="this.$store.getters.GET_LOADER_VISIBLE" />
    <div class="container">
      <router-view />
    </div>
</template>
<script>
import Loader from "../components/static/Loader";
import VVanta from 'vue-vanta';

export default {
  data() {
    return {
      options: {
        el: "#app",
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 400.00,
        speed: 1.70
      }
    }
  },
  name: "AuthLayout",
  components: {Loader, VVanta},
  mounted() {
    //document.querySelector('body').classList.add('bg-gradient-primary');
  },
  unmounted() {
    //document.querySelector('body').classList.remove('bg-gradient-primary');
  },
}
</script>
<style scoped></style>
