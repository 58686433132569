import axios from 'axios'
import router from "./router";
import store from "./store"

let api = (document.location.host === 'crm.broker')?'https://api.crm.broker/':'http://crmbrokerapi/';
//alert(api);
axios.defaults.baseURL = api;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
        router.push('/login');
    }
    return Promise.reject(error.response.data);
});
