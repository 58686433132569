<template>
  <router-view/>
  <!-- Page Wrapper -->
</template>
<script>
export default {
  name: "SiteLayout",
  components: {},
  computed:{
    loggedIn() {
      return this.$store.getters.GET_LOGGED_IN;
    },
  },
  methods:{
    testPost: async function () {
      let me = await axios.get(apiUrl + 'profile/me');
      console.log(me);
      console.log('testPost');
    }
  }
}
</script>
<style scoped></style>
