import router from "../router";
import axios from "axios";
import store from "./index";
export default {
    //namespaced: true,
    state: {
        user:{},
    },
    mutations: {
        SET_AUTH_USER(state, data){
            state.user = data.user;
        },
        SET_LOGIN_FAILURE(state){
            localStorage.clear();
            state.user = {};
        },
        SET_LOGIN_LOCK(state){
            localStorage.setItem('token', '');
        },
    },
    actions: {
        async REGISTER({commit}, user) {
            let res = await axios.post('user/register', user);
            this.dispatch('LOGIN', user)
        },
        async LOGIN({commit}, user) {
            let res = await axios.post('user/login', user);
            localStorage.setItem('token', res.data.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
            await commit('SET_AUTH_USER', res.data);
            return res.data;
        },
        async USER({commit}) {
            let res = await axios.get('profile/me');
            commit('SET_AUTH_USER', res.data);
            return res.data;
        },
        async LOGOUT({commit}){
            commit('SET_LOGIN_FAILURE');
            await router.push('/login');
        },
        async LOCKOUT({commit}){
            commit('SET_LOGIN_LOCK');
            await router.push('login');
            //if(this.getters.GET_LOGGED_IN) await axios.post(apiUrl + 'user/logout');
        },
    },
    getters: {
        GET_USER: state => state.user,
        GET_TOKEN: state => state.token,
    }
}
