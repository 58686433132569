export default [
    {
        path: '/login',
        name: 'Login',
        meta: {
            layout: 'AuthLayout'
        },
        component: () => import('../views/auth/LoginPage')
    },
    {
        path: '/lock',
        name: 'Lock',
        meta: {
            layout: 'AuthLayout'
        },
        component: () => import('../views/auth/LoginPage')
    },
    {
        path: '/forgot',
        name: 'Forgot',
        meta: {
            layout: 'AuthLayout'
        },
        component: () => import('../views/auth/ForgotPage')
    },
    {
        path: '/create',
        name: 'Create',
        meta: {
            layout: 'AuthLayout',
        },
        component: () => import('../views/auth/CreatePage')
    }
]
