<template>
  <footer id="footer" class="sticky-footer bg-white py-3">
    <div class="container-fluid">
      <div class="copyright d-flex align-items-center small text-muted">
        <span>
          По вопросам работы в CRM пишите в Telegram 89037786666
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>
<style scoped>
#footer {
  /*
  position: absolute;
  bottom: 0;
  width: 100%;

  height: 60px;
  background-color: #f5f5f5;
  */
}
</style>
