<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button v-on:click="this.$store.dispatch('TOGGLE_SIDENAV')" id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
      <i class="fa fa-bars"></i>
    </button>

    <!-- Topbar Search -->
    <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
      <div class="input-group">
        <input type="text" class="form-control bg-light border-0 small" placeholder="Поиск..." aria-label="Search" aria-describedby="basic-addon2">
        <div class="input-group-append">
          <button v-on:click="Alert('Еще не работает')" class="btn btn-primary" type="button">
            <i class="fas fa-search fa-sm"></i>
          </button>
        </div>
      </div>
    </form>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
      <li class="nav-item dropdown no-arrow d-sm-none">
        <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-search fa-fw"></i>
        </a>
        <!-- Dropdown - Messages -->
        <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
          <form class="form-inline mr-auto w-100 navbar-search">
            <div class="input-group">
              <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                  <i class="fas fa-search fa-sm"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link" target="_blank" href="https://szbm.ru">
          <span class="mr-2 d-none d-lg-inline text-gray-600 small">Перейти на сайт szbm.ru</span>
        </a>
      </li>



      <div class="topbar-divider d-none d-sm-block"></div>
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ this.$store.getters.GET_USER.name }}</span>
          <img class="img-profile rounded-circle" src="/img/undraw_profile.svg">
        </a>
        <!-- Dropdown - User Information -->
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
          <router-link v-if="comment" class="dropdown-item" to="/home/profile">
            <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i> Профиль
          </router-link>
          <router-link v-if="comment" class="dropdown-item" to="/home/settings">
            <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i> Настройки
          </router-link>
          <a v-if="comment" class="dropdown-item" href="#">
            <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i> Activity Log
          </a>
          <div v-if="comment" class="dropdown-divider"></div>
          <a v-if="comment" class="dropdown-item" v-on:click="this.$store.dispatch('LOCKOUT')" href="#">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Заблокировать
          </a>
          <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Выйти
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import store from "../../store";
import router from "../../router";

export default {
  name: "TopBar",
  mounted() {
    let timerId = setInterval(this.UpdateData, 4000);

  },
  data() {
    return {
      update: false,
      comment: false
    }
  },
  computed:{
    accountInRub(){
      return '₽' + (this.$store.getters.GET_USER.balance/100).toFixed(2);
    }
  },
  methods:{
    UpdateData: function(){
      this.update = true;
      this.error = '';
      try {
        this.$store.dispatch('USER');
      }
      catch (error) {
        console.log(error)
        this.error = error;
      }
      this.update = false;
    },
    Alert: function (str) {
      alert(str);
    },
    OnChangeSelectedProject: function () {
      this.$router.push({ name: this.$route.name, params: { project: this.$route.params.project } });
    }
  },
}
</script>
<style scoped></style>
