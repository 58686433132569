<template>
  <div class='loader'>
    <div class='spinner-grow text-primary' role='status'>
      <span class='sr-only'>Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader"
}
</script>
<style scoped>

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #ffffffa1;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.loader .spinner-grow {
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  width: 40px;
  height: 40px
}

.no-loader .loader {
  visibility: hidden;
  opacity: 0
}

</style>
