require('./config'); //
require('./bootstrap'); // все js
import './assets/scss/sb-admin-2.scss'; // все css

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
//directives
import PhoneDirective from './directives/PhoneDirective';
import FocusDirective from './directives/FocusDirective';



const app = createApp(App).use(store).use(router);

app.mount('#app');
// Регистрируем глобальную пользовательскую директиву `v-focus`
app.directive('phone', PhoneDirective);
app.directive('focus', FocusDirective);
