<template>
  <a v-bind:style="style" v-on:click="ScrollToTop" ref="scroll-to-top" class="scroll-to-top rounded" href="#">
    <i class="fas fa-angle-up"></i>
  </a>
</template>
<script>
export default {
  name: "ScrollToTop",
  data(){
    return {
      style:{
        opacity: 0,
        display: 'none'
      }
    }
  },
  methods:{
    ScrollToTop: function (){
      event.preventDefault();
      scrollTo(0,0);
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      let res = pageYOffset/500;
      if(res <= 1 && res > 0 ){
        this.style.display='block';
        this.style.opacity = res;
      }
      if(res === 0) this.style.display = 'none';
    })
  },
  unmounted() {
    window.removeEventListener('scroll', () => {

    });
  }
}
</script>
<style scoped></style>
