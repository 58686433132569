<template></template>
<script>
export default {
  name: "FocusDirective",
  mounted(el) {
    // Переключаем фокус на элемент
    el.focus()
  }
}
</script>
<style scoped></style>
