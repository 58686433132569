import {createRouter, createWebHistory} from 'vue-router'
import store from '../store'

import AuthRoutes from './auth';
import HomeRoutes from './home';
import SiteRoutes from './site';

const routes = [
    ...AuthRoutes,
    ...HomeRoutes,
    ...SiteRoutes,
    { //404 pgae
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound')
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

//
router.beforeEach(async (to, from) => {
    store.commit('SET_LOADER_VISIBLE', true);
    if (to.meta.layout === 'HomeLayout') {
        let user = store.getters.GET_USER.name ? store.getters.GET_USER : (await store.dispatch('USER')).user;
    }



    /*

    if(to.meta.layout === 'HomeLayout'){
        let user = store.getters.GET_USER.name ? store.getters.GET_USER:(await store.dispatch('USER')).user;

        if(!user.projects.length) {
            return '/home/new-project';
        }
    }

*/
    //if(!store.getters.GET_IS_PROJECTS) return '/home/new-project';
    //Если обновили страницу

})

export default router
