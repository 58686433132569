export default [
    {
        path: '/home',
        name: 'Home.Index',
        component: () => import('../views/home/IndexPage'),
        meta: {
            layout: 'HomeLayout'
        },
    },
    {
        path: '/home/new-clients',
        name: 'Home.NewClients',
        component: () => import('../views/home/NewClientsPage'),
        meta: {
            layout: 'HomeLayout'
        },
    },
]
    /*
    .map(rout => { //Добавляем префикс
    rout.path = '/home' + rout.path;
    return rout;
})
*/
