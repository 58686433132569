import {createStore} from 'vuex'
import auth from './auth';
import axios from "axios";

export default createStore({
    state: {
        WITH:[
            'project_servers'
        ],
        SIDENAV_TOGGLED: false,
        LOADER_VISIBLE: true,
        DEBUG: true, //Убрать при продакшен
    },
    mutations: {
        SET_SIDENAV_TOGGLED(state, togged) {
            return state.SIDENAV_TOGGLED = togged;
        },
        SET_LOADER_VISIBLE(state, visible) {
            return state.LOADER_VISIBLE = visible;
        },
        SET_PROJECT(state, project){
            state.auth.user.projects.push(project);
        },
        // DEL_PROJECT(state, id) {
        //     state.auth.user.projects.find((project, index, array) => project.id === id ? array.splice(index, 1) : false);
        // },
        SET_TRANSACTIONS(state, transactions) {
            state.auth.user.transactions = transactions;
            return transactions;
        },
        SET_SERVERS(state, server) {
            return state.auth.user.projects.map(project => project.id === server.service.project_id ? project.servers.push(server) : project)
        },
        SET_DOMAIN(state, domain) {
            return state.auth.user.projects.map(project => project.id === domain.project_id ? project.domains.push(domain) : project)
        },
        SET_CERTIFICATE(state, certificate) {
            return state.auth.user.projects.map(project => project.id === certificate.project_id ? project.certificates.push(certificate) : project)
        },
        DELETE_DOMAIN(state, domain) {
            return state.auth.user.projects[state.auth.user.projects.findIndex(projects => projects.id === domain.project_id)].domains.splice(state.auth.user.projects[state.auth.user.projects.findIndex(projects => projects.id === domain.project_id)].domains.findIndex(d => d.id === domain.id), 1);
        },
        DELETE_CERTIFICATE(state, certificate) {
            return state.auth.user.projects[state.auth.user.projects.findIndex(projects => projects.id === certificate.project_id)].certificates.splice(state.auth.user.projects[state.auth.user.projects.findIndex(projects => projects.id === certificate.project_id)].certificates.findIndex(c => c.id === certificate.id), 1);
        },
        SET_BALANCE(state,balance){
            return state.auth.user.balance = balance;
        },
    },
    actions: {
        async UPDATEDATA({commit}){
            let res = await axios.get('update/data');
            console.log(res);
        },
        TOGGLE_SIDENAV({commit}, payload) {
            document.querySelector('body').classList.toggle('sidebar-toggled');
            let sidebar = document.getElementById('accordionSidebar');
            sidebar.classList.toggle('toggled');
            if (!this.getters.GET_SIDENAV_TOGGLED) {
                let link = document.querySelector('a[aria-expanded="true"]');
                if (link) {
                    let collapse = link.nextSibling;
                    link.setAttribute('aria-expanded', false);
                    link.classList.add("collapsed");
                    collapse.classList.remove('show');
                }
            }
            commit('SET_SIDENAV_TOGGLED', !this.getters.GET_SIDENAV_TOGGLED);
        },
        async NEW_CLIENTS({commit}){
            return (await axios.post('clients/new')).data.clients;
        },
        async TRANSACTIONS({commit}, payload) {
            commit('SET_TRANSACTIONS', (await axios.get('transaction/getConfirmed')).data.transactions);
        },
        async ADD_SERVER({commit}, payload) { //
            //return commit('SET_SERVERS', (await axios.post('project/addServer', payload)).data.server);
            return (await axios.post('project/addServer', payload)).data.server;
        },
        async ADD_DOMAIN({commit}, payload) {
            return commit('SET_DOMAIN', (await axios.post('domain/new', payload)).data.domain);
        },
        async ADD_CERTIFICATE({commit}, payload) {
            return commit('SET_CERTIFICATE', (await axios.post('certificate/new', payload)).data.certificate);
        },
        async DELETE_PROJECT_SERVER({commit}, payload) {
            return await axios.post('project/delServer', payload);
            //commit('DELETE_PROJECT_SERVER', payload);
        },
        async DELETE_DOMAIN({commit}, domain) {
            await axios.post('domain/delete', {domain_id:domain.id});
            commit('DELETE_DOMAIN', domain);
        },
        async DELETE_CERTIFICATE({commit}, certificate) {
            await axios.post('certificate/delete', {certificate_id:certificate.id});
            commit('DELETE_CERTIFICATE', certificate);
        },
        async UPDATE_BALANCE({commit}, payload) {
            commit('SET_BALANCE', (await axios.get('profile/balance')).data.balance);
        },
        async UPDATE_PROJECTS({commit}, payload) {
            //commit('SET_BALANCE', (await axios.get('profile/balance')).data.balance);
        }
    },
    getters: {
        GET_SIDENAV_TOGGLED: state => state.SIDENAV_TOGGLED,
        GET_PROJECTS: state => state.auth.user.projects,
        //GET_PROJECT: state => id => state.auth.user.projects ? state.auth.user.projects.find(project => project.id === id) : [],
        GET_LOADER_VISIBLE: state => state.LOADER_VISIBLE,
        GET_DEBUG: state => state.DEBUG,
        GET_TRANSACTIONS: state => status => state.auth.user.transactions.slice().reverse().filter(item => item.status === status),
        GET_TRANSACTIONS_ALL: state => state.auth.user.transactions.slice().reverse(),
        GET_TRANSACTION: state => transaction_id => state.auth.user.transactions.find(item => item.id === transaction_id),
        GET_ALERT: state => state.ALERTS,
        //GET_CARD_COUNT: state =>
        GET_CARDS: state => {
            let project = state.auth.user.projects[0];
            return state.WITH.map(function(item,index, array){
                return {
                    cat: item
                }
            });
        },
    },
    modules: {
        auth,
    }
})
