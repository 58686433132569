<template>
  <div class="modal fade show" id="alert" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="alertLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="alertLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Alert",
  computed:{
    alert: function () {
      if(this.$store.getters.GET_ALERT === false)
      return this.$store.getters.GET_ALERT;
    }
  },
  emits:{
    yes: null,
    no: null,
  }
}
</script>
<style scoped></style>
